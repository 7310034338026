<template>
  <div class="view view--login">
    <div class="view-body">
      <Section>
        <Container>
          <div class="login-block">
            <router-link :to="{ name: 'home' }" class="navbar-logo">
            <img
              class="login-logo"
              alt="Le logo de OSS"
              src="../../assets/logo-oss-full.svg"
            />
          </router-link>

            <h1>Mot de passe oublié&nbsp;?</h1>

            <p>
              Veuillez indiquer votre identifiant, afin de générer un nouveau
              mot de passe.
            </p>

            <div class="form form--login">
              <form @submit.prevent="onSubmit()">
                <Input
                  id="identifiant"
                  label="Identifiant"
                  placeholder="Ex : johndoe1234"
                />

                <Btn
                  btnType="submit"
                  text="Générer un nouveau mot de passe"
                  color="primary"
                  block
                  icon="key"
                />
              </form>
            </div>
            <p>
              <router-link :to="{ name: 'usernameForgotten' }"
                >Identifiant oublié&nbsp;?</router-link
              >
            </p>
            <p>
              <router-link :to="{ name: 'login' }">Se connecter</router-link>
            </p>
          </div>
        </Container>
      </Section>
    </div>
  </div>
</template>

<script>
import Input from '@/components/form/Input.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import * as yup from 'yup'

export default {
  name: 'PasswordForgottenView',

  components: {
    Input,
    Btn,
    Section,
    Container,
  },

  data() {
    // Define a validation schema
    const validationSchema = yup.object().shape({
      identifiant: yup.string().required(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(validationSchema)
    const onSubmit = this.formService.handleSubmit((values) => {
      this.sendRequest(values)
    })

    return {
      onSubmit,
      errors,
      isSubmitting,
    }
  },
  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },
  methods: {
    sendRequest(values) {
      return this.fetchService.post('authentification/mdp', values).then(
        (response) => {
          this.formService.resetForm()
          this.emitter.emit('alert', {
            type: 'success',
            content: response.data,
          })
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
        },
      )
    },
  },
}
</script>
